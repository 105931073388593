import React, { useContext, useState } from "react"
import "twin.macro"
import styled from "styled-components"
import cogoToast from "@clevertrackdk/cogo-toast"
import Icon from "lib/Icon"
import { Button, ButtonGroup, Responsive } from "@clevertrack/shared"
import I18n from "app/I18n"
import PopOver from "app/PopOver"
import { Grid } from "app/Grid"

import { updateVehiclesInGroup } from "services/vehicles"
import { createGroup, updateGroup, deleteGroup } from "services/groups"

import GroupSelector from "./GroupSelector"
import GroupTrackerSearch from "./GroupTrackerSearch"
import GroupForm from "./GroupForm"

import { StyledTile, StyledTileContainer } from "../Users"
import { useDevices } from "app/Device/hooks"
import { IDevice, IDeviceGroup } from "app/Device/types"
import { DialogModal } from "app/Dialog"
import { useFirestoreCompany } from "services/firestore/company"
import { useTrackerGroups } from "app/TrackerGroups/hooks"
import { CompanyContext } from "app/Company/context"
import useDevicesStore from "app/Device/store"

/* const StyledGrid = styled(Grid)`
  header {
    display: flex;
    position: sticky;
    align-items: center;
    top: 0;
    justify-content: space-between;
    padding: 2rem 0;
    z-index: 200;
    background: ${(props) => props.theme.colors.white};
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    display: grid;
    grid-template-columns: 1.5fr 1.5fr;
    grid-column-gap: 4rem;
    padding: 2rem 2rem 6rem;
    max-width: none;
    margin: 0 auto;

    header {
      display: flex;
      position: relative;
      align-items: center;
      top: 0;
      justify-content: space-between;
      padding: 2rem 0;
      background: none;
    }

    h1 {
      display: block;
      margin: 0;
      background: ${props.theme.colors.white};
      z-index: 20;
    }
  `}

  ${(props) => props.theme.media.desktop_up`
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 2fr;
    grid-column-gap: 4rem;
    padding: 2rem 2rem 6rem;
    max-width: none;
    margin: 0 auto;

    header {
      display: flex;
      position: relative;
      align-items: center;
      top: 0;
      justify-content: space-between;
      padding: 2rem 0;
      background: none;
    }

    h1 {
      display: block;
      margin: 0;
      background: ${props.theme.colors.white};
      z-index: 20;
    }
  `}
` */

const StyledGroupFormWrapper = styled.div`
  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 0;
  `}
`

function Groups({ ...props }) {
  const { syncAddress } = useDevicesStore((state) => ({
    syncAddress: state.syncAddress,
  }))

  const {
    state: { company },
  } = useContext(CompanyContext)

  const [deleteGroupId, setDeleteGroupId] = useState(null)
  const { deviceGroups, devices, setDevicesAndGroups } = useDevices(syncAddress)
  const { getGroups } = useTrackerGroups()
  const [selectedGroup, setSelectedGroup] = useState<Partial<IDeviceGroup>>({
    name: "<Unavngiven gruppe>",
  })
  const [showGroupForm, setShowGroupForm] = useState(false)
  const [showTrackerSelector, setShowTrackerSelector] = useState(false)
  const [devicesInGroup, setDevicesInGroup] = useState<IDevice[]>([])
  const {
    deleteFirebaseCompanyDeviceGroup,
    saveFirebaseCompanyDeviceGroup,
  } = useFirestoreCompany()

  async function onUpdateVehicles(id, result, isNew) {
    const vehicleIds = devicesInGroup.map((tracker) => tracker.id)
    try {
      const updateVehiclesResponse = await updateVehiclesInGroup(id, {
        group_id: id,
        vehicles: vehicleIds,
      })
      if (updateVehiclesResponse.data.result === "OK") {
        isNew
          ? cogoToast.success("Ny enhedsgruppe oprettet")
          : cogoToast.success(`Opdaterede enhedsgruppen '${result.name}'`)
      } else {
        cogoToast.error(
          `Noget gik galt under opdateringen af enhederne i gruppen '${result.name}'`
        )
      }
    } catch (error) {
      console.log(error)
      cogoToast.error(
        "Kunne ikke opdatere enhederne i gruppen på nuværende tidspunkt"
      )
    }
  }

  async function onSaveHandler(id, body) {
    if (selectedGroup === null) {
      try {
        // Create new
        const createResponse = await createGroup(body)
        if (createResponse.data.result === "OK") {
          if (devicesInGroup.length > 0) {
            await onUpdateVehicles(
              createResponse.data.id,
              createResponse.data,
              true
            )
          } else {
            cogoToast.success("Ny gruppe oprettet")
          }
          const createdGroup = await getGroups(createResponse.data.id)
          if (createdGroup) setSelectedGroup(createdGroup)
        } else {
          cogoToast.error(`Noget gik galt under oprettelsen af ny gruppe`)
        }
      } catch (e) {
        cogoToast.error(
          "Kunne ikke oprette en ny gruppe på nuværende tidspunkt"
        )
      }
    } else {
      // Save edit
      try {
        const updateResponse = await updateGroup(id, body)
        if (updateResponse?.data.result === "OK" && updateResponse?.data.id) {
          await onUpdateVehicles(
            updateResponse.data.id,
            updateResponse.data,
            false
          )
          if (devicesInGroup.length === 0) {
            cogoToast.warn(`Tom enhedsgruppe '${body.name}', overvej at slette`)
          }
          const updatedGroup = await getGroups(updateResponse.data.id)
          if (updatedGroup) setSelectedGroup(updatedGroup)
        } else {
          cogoToast.error(
            `Noget gik galt under opdateringen af gruppen '${body.name}'`
          )
        }
      } catch (e) {
        cogoToast.error(
          `Kunne ikke opdatere enhedsgruppen '${body.name}' på nuværende tidspunkt`
        )
      }
    }
  }

  function onDeleteHandler(id) {
    setDeleteGroupId(id)
  }

  async function onConfirmDeleteHandler(id) {
    try {
      const response = await deleteGroup(id)

      if (response.data.result === "OK") {
        const firebaseResult = await deleteFirebaseCompanyDeviceGroup(id)
        setDeleteGroupId(null)
        await getGroups()
        onCancelHandler()
        return cogoToast.success("Gruppe slettet")
      } else {
        cogoToast.error(`Noget gik galt. ${response.data.result}`)
      }
    } catch {
      cogoToast.error("Kunne ikke slette gruppen på nuværende tidspunkt.")
    }
  }

  function onAddTrackerToGroup(id) {
    const device = devices.find((d) => d.id === id)
    const newDevicesInGroup = devicesInGroup.slice()
    const newDevices = [device, ...newDevicesInGroup]
    setDevicesInGroup(newDevices)
  }

  function onRemoveTrackerFromGroup(id) {
    const newDevices = devicesInGroup.filter((device) => device.id !== id)
    setDevicesInGroup(newDevices)
  }

  function onSelectGroup(group) {
    setSelectedGroup(group)
    const newDevicesInGroup = devices
      .map((device) => {
        return !!device.group.find((group_id) => group_id === group.id)
          ? device
          : null
      })
      .filter(Boolean)
    setDevicesInGroup(newDevicesInGroup)
    setShowGroupForm(true)
  }

  function onNewGroupHandler() {
    setSelectedGroup(null)
    setDevicesInGroup([])
    setShowGroupForm(true)
  }

  function onCancelHandler() {
    setSelectedGroup(null)
    setShowGroupForm(false)
    setShowTrackerSelector(false)
  }

  function onOpenTrakcerSearch() {
    setShowTrackerSelector(true)
  }

  function onCloseTrakcerSearch() {
    setShowTrackerSelector(false)
  }

  const renderForm = () => {
    return selectedGroup !== null ? (
      <>
        <header>
          <h3>
            <I18n strKey="edit" /> <I18n strKey="group" />
          </h3>
        </header>
        <StyledGroupFormWrapper>
          <GroupForm
            key={selectedGroup.id}
            onSave={onSaveHandler}
            onCancel={onCancelHandler}
            {...selectedGroup}
          />
        </StyledGroupFormWrapper>
      </>
    ) : (
      <>
        <header>
          <h3>
            <I18n strKey="create" /> <I18n strKey="group" />
          </h3>
        </header>
        <StyledGroupFormWrapper>
          <GroupForm
            key="new_group"
            onSave={onSaveHandler}
            onCancel={onCancelHandler}
          />
        </StyledGroupFormWrapper>
      </>
    )
  }

  const renderList = () => {
    return (
      <>
        <header tw="hidden md:flex">
          <h3>
            <I18n strKey="groups_plural" />
          </h3>
          <Button
            icon="right"
            size="sm"
            variant="primary"
            onClick={onNewGroupHandler}
          >
            <span>
              <I18n strKey="new" /> <I18n strKey="group" />
            </span>
            <Icon size="sm" icon="plus" />
          </Button>
        </header>
        <GroupSelector
          groups={deviceGroups}
          onToggleGroup={onSelectGroup}
          onCancel={onCancelHandler}
          onDelete={onDeleteHandler}
          selectedGroup={selectedGroup && selectedGroup.id}
        />
        <ButtonGroup tw="mt-auto md:hidden px-8 bg-white" sticky="bottom">
          <Button icon="right" variant="primary" onClick={onNewGroupHandler}>
            <span>
              <I18n strKey="new" /> <I18n strKey="group" />
            </span>
            <Icon size="sm" icon="plus" />
          </Button>
        </ButtonGroup>
      </>
    )
  }

  return (
    <>
      <Grid gridConfig="60rem 1.5fr 2fr">
        <div>{renderList()}</div>
        <div>
          <Responsive
            phone={
              <PopOver show={showGroupForm} fromRight>
                <StyledTileContainer>
                  <StyledTile>{renderForm()}</StyledTile>
                </StyledTileContainer>
                <ButtonGroup sticky tw="px-8 bg-white">
                  <Button variant="default" onClick={onOpenTrakcerSearch}>
                    <I18n strKey="trackers" />
                  </Button>
                </ButtonGroup>
              </PopOver>
            }
            tabletLandscape={showGroupForm ? renderForm() : null}
          />
        </div>
        <div>
          <Responsive
            phone={
              <PopOver show={showTrackerSelector} fromBottom>
                <StyledTileContainer>
                  <StyledTile>
                    <GroupTrackerSearch
                      trackers={devices}
                      trackersInGroup={devicesInGroup}
                      onAddTrackerToGroup={onAddTrackerToGroup}
                      onRemoveTrackerFromGroup={onRemoveTrackerFromGroup}
                      selectedGroup={selectedGroup}
                    />
                  </StyledTile>
                </StyledTileContainer>
                <ButtonGroup tw="mt-4 px-8 bg-white" sticky>
                  <Button variant="default" onClick={onCloseTrakcerSearch}>
                    <I18n strKey="close" />
                  </Button>
                </ButtonGroup>
              </PopOver>
            }
            tabletLandscape={
              showGroupForm && (
                <GroupTrackerSearch
                  trackers={devices}
                  trackersInGroup={devicesInGroup}
                  onAddTrackerToGroup={onAddTrackerToGroup}
                  onRemoveTrackerFromGroup={onRemoveTrackerFromGroup}
                  selectedGroup={selectedGroup}
                />
              )
            }
          />
        </div>
      </Grid>
      <DialogModal
        open={deleteGroupId !== null}
        onClose={() => setDeleteGroupId(null)}
      >
        <div tw="bg-white shadow-xl p-8 lg:(w-1/3)">
          {deleteGroupId && (
            <h2 tw="m-0 mt-4 text-4xl">
              Slet gruppen '
              {deviceGroups.find((x) => x.id === deleteGroupId).name}'?
            </h2>
          )}
          <p tw="text-xl mt-0 leading-snug">
            Gruppen er synlig for alle brugere. Hvis du sletter den, forsvinder
            den også for andre brugere.
          </p>
          <p tw="text-xl leading-snug">
            Ønsker du i stedet at skjule gruppen på din egen konto, kan du bruge{" "}
            <Icon icon="eye" />
            -ikonet til venstre for gruppen i stedet.
          </p>
          <ButtonGroup>
            <Button
              appearance="link"
              variant="transparent"
              type="button"
              onClick={() => setDeleteGroupId(null)}
            >
              <I18n strKey="cancel" />
            </Button>
            <Button
              type="button"
              variant="danger"
              onClick={() => onConfirmDeleteHandler(deleteGroupId)}
            >
              Ja, slet gruppen
            </Button>
          </ButtonGroup>
        </div>
      </DialogModal>
    </>
  )
}

export default Groups

Groups.defaultProps = {}
Groups.propTypes = {}
